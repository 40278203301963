import { CSSProperties, FC } from 'react';

export const DashboardWidget: FC = (props) => {
  return <>
    <section style={style}>
      {props.children}
    </section>
  </>
}

const style: CSSProperties = {
  flexBasis: '400px',
  aspectRatio: '16 / 9',
  flexGrow: 1,
  margin: 5,
};